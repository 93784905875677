module.exports = [{
      plugin: require('/Users/Vinnistry/Documents/Web/portfolio-blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960},
    },{
      plugin: require('/Users/Vinnistry/Documents/Web/portfolio-blog/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Vinnistry/Documents/Web/portfolio-blog/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[""],"pluginConfig":{"head":true}},
    },{
      plugin: require('/Users/Vinnistry/Documents/Web/portfolio-blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Vinnistry/Documents/Web/portfolio-blog/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Vinnistry/Documents/Web/portfolio-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
